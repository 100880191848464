import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/contact.css";

function Contact() {
  const [name, setName] = useState("");
  const [phonetic, setPhonetic] = useState("");
  const [conpanyName, setCompanyName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handlePlaceOrder = (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!email) newErrors.email = "Email is required";
    if (!message) newErrors.message = "Message is required";
    if (!name) newErrors.name = "Name is required";

    // setErrorMessage(newErrors);

    if (Object.keys(newErrors).length > 0) {
      alert("Please fill all required fields");
    } else {
      alert(
        "Thanks for contacting us with your comments and questions. We'll respond to you very soon."
      );
      setName("");
      setEmail("");
      setMessage("");
    }
  };

  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="container-fluid">
          {/* top */}
          <div className="top-container">
            <h1>
              CONTACT &nbsp;
              <p style={{ fontSize: "20px", marginLeft: "-2%" }}>
                -お問い合わせ-
              </p>
            </h1>
            <div>
              <img alt="" src="/head-foot/mainvisual1.webp" />
            </div>
            <section className="page_contents_center_low fadeinup">
              <div className="page_subheading_text">
                <p>
                  お問い合わせ内容をご入力の上、「確認」ボタンを押してください。
                </p>
                <p>
                  <span className="fontcolor_pink">*</span>
                  は入力必須になります。
                </p>
              </div>
            </section>

            <section className="contents_right_min fadeinright">
              <div className="contents_right_bg">
                <div id="inner">
                  <div
                    className="wpcf7 js"
                    id="wpcf7-f5-o1"
                    lang="ja"
                    dir="ltr"
                  >
                    <div className="screen-reader-response">
                      <p
                        role="status"
                        aria-live="polite"
                        aria-atomic="true"
                      ></p>
                      <ul></ul>
                    </div>
                    <form className="wpcf7-form resetting">
                      <div>
                        <input type="hidden" name="_wpcf7" value="5" />
                        <input
                          type="hidden"
                          name="_wpcf7_version"
                          value="5.9.8"
                        />
                        <input type="hidden" name="_wpcf7_locale" value="ja" />
                        <input
                          type="hidden"
                          name="_wpcf7_unit_tag"
                          value="wpcf7-f5-o1"
                        />
                        <input
                          type="hidden"
                          name="_wpcf7_container_post"
                          value="0"
                        />
                        <input
                          type="hidden"
                          name="_wpcf7_posted_data_hash"
                          value=""
                        />
                      </div>
                      <table summary="お問い合わせ">
                        <tbody>
                          <tr>
                            <th>
                              <p>
                                お名前<span className="fontcolor_red">*</span>
                              </p>
                            </th>
                            <td>
                              <p>
                                <span
                                  className="wpcf7-form-control-wrap"
                                  data-name="your-name"
                                >
                                  <input
                                    size="40"
                                    maxlength="400"
                                    className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                    aria-required="true"
                                    aria-invalid="false"
                                    placeholder=""
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    type="text"
                                    name="your-name"
                                  />
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <p>ふりがな</p>
                            </th>
                            <td>
                              <p>
                                <span
                                  className="wpcf7-form-control-wrap"
                                  data-name="your-ruby"
                                >
                                  <input
                                    size="40"
                                    maxlength="400"
                                    className="wpcf7-form-control wpcf7-text"
                                    aria-invalid="false"
                                    placeholder=""
                                    type="text"
                                    name="your-ruby"
                                    value={phonetic}
                                    onChange={(e) =>
                                      setPhonetic(e.target.value)
                                    }
                                  />
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <p>会社名</p>
                            </th>
                            <td>
                              <p>
                                <span
                                  className="wpcf7-form-control-wrap"
                                  data-name="your-company"
                                >
                                  <input
                                    size="40"
                                    maxlength="400"
                                    className="wpcf7-form-control wpcf7-text"
                                    aria-invalid="false"
                                    placeholder="株式会社 GOLKANO."
                                    value={conpanyName}
                                    onChange={(e) =>
                                      setCompanyName(e.target.value)
                                    }
                                    type="text"
                                    name="your-company"
                                  />
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <p>電話番号</p>
                            </th>
                            <td>
                              <p>
                                <span
                                  className="wpcf7-form-control-wrap"
                                  data-name="your-tel"
                                >
                                  <input
                                    size="40"
                                    maxlength="400"
                                    className="wpcf7-form-control wpcf7-tel wpcf7-text wpcf7-validates-as-tel"
                                    aria-invalid="false"
                                    placeholder="03-5851-8681"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    type="tel"
                                    name="your-tel"
                                  />
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <p>
                                メールアドレス
                                <span className="fontcolor_red">*</span>
                              </p>
                            </th>
                            <td>
                              <p>
                                <span
                                  className="wpcf7-form-control-wrap"
                                  data-name="your-email"
                                >
                                  <input
                                    size="40"
                                    maxlength="400"
                                    className="wpcf7-form-control wpcf7-email wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-email"
                                    aria-required="true"
                                    aria-invalid="false"
                                    placeholder="sky@nautal.life"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    type="email"
                                    name="your-email"
                                  />
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <p>
                                お問合せ内容
                                <span className="fontcolor_red">*</span>
                              </p>
                            </th>
                            <td>
                              <p>
                                <span
                                  className="wpcf7-form-control-wrap"
                                  data-name="your-message"
                                >
                                  <textarea
                                    cols="40"
                                    rows="10"
                                    maxlength="2000"
                                    className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
                                    aria-required="true"
                                    aria-invalid="false"
                                    placeholder="こちらにお問い合わせ内容を入力してください。"
                                    name="your-message"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                  ></textarea>
                                </span>
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div
                        className="wpcf7-response-output"
                        aria-hidden="true"
                      ></div>
                      <p>
                        弊社の<a href="../privacy/">プライバシーポリシー</a>
                        をご確認の上、内容にお間違いがなければ確認ボタンをクリックして下さい。
                      </p>
                      <p className="center">
                        <br />
                        <input
                          className="wpcf7-form-control wpcf7-submit has-spinner"
                          type="submit"
                          value="確認"
                          onClick={handlePlaceOrder}
                        />
                        <span className="wpcf7-spinner"></span>
                      </p>
                      <div className="inv-recaptcha-holder">
                        <div
                          className="grecaptcha-badge"
                          data-style="bottomright"
                        >
                          <div className="grecaptcha-logo">
                            <iframe
                              title="reCAPTCHA"
                              width="256"
                              height="60"
                              role="presentation"
                              name="a-u0t2gkhelxsi"
                              frameborder="0"
                              scrolling="no"
                              sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
                              src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6Lca7sYUAAAAAL0MG2BBrJRd_VI5hxNKysDNfA3l&amp;co=aHR0cHM6Ly9nb2xrYW5vLmNvLmpwOjQ0Mw..&amp;hl=ja&amp;v=EGbODne6buzpTnWrrBprcfAY&amp;size=invisible&amp;badge=bottomright&amp;cb=ar5p076ql4wn"
                            ></iframe>
                          </div>
                          <div className="grecaptcha-error"></div>
                          <textarea
                            id="g-recaptcha-response"
                            name="g-recaptcha-response"
                            className="g-recaptcha-response"
                          ></textarea>
                        </div>
                        <iframe></iframe>
                      </div>
                    </form>
                  </div>
                  <div id="form_contents_text">
                    <p>
                      <span className="fontcolor_pink">※</span>
                      ご入力いただきました個人情報につきましては、お客様への回答、およびお問い合わせにもとづくサービスなど目的以外には利用いたしません。提供するサービスについてのお問い合わせ内容は、今後のサービス拡充の参考のため個人を特定しない統計処理にて利用させていただく場合があります。
                    </p>
                    <p>
                      <span className="fontcolor_pink">※</span>
                      提供するサービスのお申込み内容によっては、弊社が適当と判断した配送事業者および業務提携先にお客様の住所・氏名・電話番号などの個人情報を預託する場合があります。
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
